import {
  IDeviceStatusSummary,
  DeviceStatusCode,
  DeviceStatusInfo,
} from '@desquare/models';
import { Maybe } from 'graphql/jsutils/Maybe';

/** utility class to handle sets of device statuses, used in map clusters and multi device channels */
export class DeviceStatusSummary implements IDeviceStatusSummary {
  Online = 0;
  CtrlOffline = 0;
  PanelOff = 0;
  Offline = 0;
  Info = 0;
  Warning = 0;
  DevicesCount = 0;

  constructor(statuses: Maybe<DeviceStatusInfo>[]) {
    statuses.forEach((status) => {
      if (status?.Status === DeviceStatusCode.online) {
        this.Online++;
      } else {
        this.Offline++;
      }
      this.DevicesCount++;
    });

    // a channel without any device will show as offline
    if (this.DevicesCount === 0) {
      this.Offline = 1;
    }
  }

  /** returns a single status info summary of multiple statuses */
  getMixedStatusValue() {
    if (this.DevicesCount === 0) {
      return undefined;
    }
    if (this.Online > 0 && this.Online === this.DevicesCount) {
      return DeviceStatusInfo.Online;
    }
    if (this.Online > 0 && this.Offline > 0) {
      return DeviceStatusInfo.Warning;
    }

    return DeviceStatusInfo.Offline;
  }
}
